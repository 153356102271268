import '@archilogic/floor-plan-sdk/dist/style.css';

import Alpine from 'alpinejs';
import { FloorPlanEngine, FpePointerEvent } from '@archilogic/floor-plan-sdk';

type Marker = ReturnType<typeof FloorPlanEngine.prototype.addHtmlMarker>;
type LayoutSpace = ReturnType<
  typeof FloorPlanEngine.prototype.getResourcesFromPosition
>['spaces'][0];
interface Comment {
  content: string;
  position: [number, number];
  spaceId: string;
  spaceName: string;
  state: 'adding' | 'saved';
}
interface ApiComment {
  content: string;
  floorId: string;
  floorPlanPositionX: number;
  floorPlanPositionY: number;
  spaceId: string;
  spaceName: string;
}

const FEEDBACK_COMMENTS_LOCAL_STORAGE_KEY = 'design-annotation-comments';

function createMarker(commentIndex: number) {
  const custom_marker = document.createElement('div');
  const inner = document.createElement('span');
  inner.innerHTML = (commentIndex + 1).toString();
  custom_marker.appendChild(inner);
  custom_marker.classList.add('comment-number');
  return custom_marker;
}

function handleProposalFeedbackPage(page: HTMLBodyElement) {
  const initialState = {
    clickedCommentIndex: -1,
    comments: {} as { [key: string]: Comment[] },
    currentFloorId: '',
    floorLoading: true,
    floors: [] as { name: string; floor_id: string }[],
    hoveredCommentIndex: -1,
    publishableAccessToken: '',
    readOnly: false,
    savedCommentCount() {
      return Object.values(this.comments)
        .flat()
        .filter((comment) => comment.state === 'saved').length;
    },
    commentsForCurrentFloorId() {
      if (!(this.currentFloorId in this.comments)) {
        this.comments[this.currentFloorId] = [];
      }
      return this.comments[this.currentFloorId];
    },
    markingDisabled() {
      return this.commentsForCurrentFloorId().some(
        (comment) => comment.state === 'adding'
      );
    },
  };
  Alpine.store('feedback', initialState);
  const state: typeof initialState = (Alpine.store as any)('feedback');

  const persistComments = () => {
    localStorage.setItem(
      FEEDBACK_COMMENTS_LOCAL_STORAGE_KEY,
      JSON.stringify(state.comments)
    );
  };

  Alpine.effect(() => {
    if (!state.floors) {
      return;
    }

    const persistedComments = localStorage.getItem(
      FEEDBACK_COMMENTS_LOCAL_STORAGE_KEY
    );
    if (persistedComments) {
      const parsedComments = JSON.parse(persistedComments);

      for (const floorId in parsedComments) {
        if (state.floors.find((floor) => floor.floor_id === floorId)) {
          const floorComments = parsedComments[floorId];
          state.comments[floorId] = floorComments;
        }
      }
    }
  });

  const getFloorplanEngineOnMouseMove =
    (floorPlanEngine: FloorPlanEngine) =>
    ({ position }: FpePointerEvent) => {
      const { spaces } = floorPlanEngine.getResourcesFromPosition(position);
      const newSpaceIds = spaces.map((s) => s.id);
      for (const space of floorPlanEngine.layout.spaces) {
        if (newSpaceIds.includes(space.id)) {
          floorPlanEngine.drawNodeUpdate(space.id, {
            fill: [200, 200, 200],
          });
        } else {
          floorPlanEngine.drawNodeUpdate(space.id, {
            fill: false,
          });
        }
      }
    };

  const getFloorplanEngineOnMouseClick =
    (floorPlanEngine: FloorPlanEngine) =>
    ({ position }: FpePointerEvent) => {
      if (state.markingDisabled() || state.readOnly) {
        return;
      }
      const { spaces } = floorPlanEngine.getResourcesFromPosition(position);
      if (spaces.length === 0) {
        return;
      }
      const space = spaces[0];
      window.requestAnimationFrame(() => beginComment(space, position));
    };

  const beginComment = (space: LayoutSpace, position: [number, number]) => {
    state.commentsForCurrentFloorId().push({
      content: '',
      position,
      spaceId: space.id,
      spaceName: space.name,
      state: 'adding',
    });
  };

  Alpine.data('dataContainer', () => ({
    initData(json: any) {
      state.publishableAccessToken = json.publishable_access_token;
      state.floors = json.floors;
      if (json.floors.length > 0) {
        state.currentFloorId = json.floors[0].floor_id;
      }
    },
  }));

  Alpine.data('commentContainer', () => ({
    saveComment() {
      state
        .commentsForCurrentFloorId()
        .forEach((comment) => (comment.state = 'saved'));
      persistComments();
    },
    dismissComment() {
      const comments = state.commentsForCurrentFloorId();
      const index = comments.findIndex((comment) => comment.state === 'adding');
      if (index > -1) {
        comments.splice(index, 1);
      }
    },
    deleteComment(commentIndex: number) {
      state.commentsForCurrentFloorId().splice(commentIndex, 1);
      persistComments();
    },
  }));

  Alpine.data('floorPlanCanvas', () => ({
    markers: [] as Marker[],
    init() {
      const floorPlanEngine = new FloorPlanEngine({
        container: this.$el,
        options: {
          theme: {
            elements: {
              roomStamp: {
                roomStampDisplay: ['name'],
              },
            },
          },
          showCeilingLamps: true,
        },
      });
      floorPlanEngine.on(
        'click',
        getFloorplanEngineOnMouseClick(floorPlanEngine)
      );
      floorPlanEngine.on(
        'mousemove',
        getFloorplanEngineOnMouseMove(floorPlanEngine)
      );

      Alpine.effect(() => {
        if (state.floorLoading) {
          return;
        }

        this.markers.forEach((marker) => marker.remove());
        this.markers = [];

        state.commentsForCurrentFloorId().forEach((comment, index) => {
          const markerElement = createMarker(index);
          const marker = floorPlanEngine.addHtmlMarker({
            el: markerElement,
            position: comment.position,
          });
          this.markers.push(marker);

          markerElement.addEventListener('mouseover', () => {
            state.hoveredCommentIndex = index;
          });
          markerElement.addEventListener('mouseout', () => {
            state.hoveredCommentIndex = -1;
          });
          markerElement.addEventListener('click', () => {
            state.clickedCommentIndex = index;
          });
        });
      });

      Alpine.effect(() => {
        if (state.currentFloorId) {
          state.floorLoading = true;
          floorPlanEngine
            .loadFloorById(state.currentFloorId, {
              publishableAccessToken: state.publishableAccessToken,
            })
            .then(() => {
              requestAnimationFrame(() => {
                state.floorLoading = false;
              });
            });
        }
      });

      Alpine.effect(() => {
        if (state.clickedCommentIndex === -1) {
          return;
        }
        const clickedComment =
          state.commentsForCurrentFloorId()[state.clickedCommentIndex];
        floorPlanEngine.zoomToElement(clickedComment.spaceId, 3, 1600);

        state.clickedCommentIndex = -1;
      });

      Alpine.effect(() => {
        // Fade non-hovered comment markers, if any comment is hovered
        this.markers.forEach((marker, index) => {
          if (index === state.hoveredCommentIndex) {
            marker.el.classList.add('highlight');
          } else if (-1 === state.hoveredCommentIndex) {
            marker.el.classList.remove('highlight');
          } else {
            marker.el.classList.remove('highlight');
          }
        });
      });
    },
  }));

  Alpine.data('submitFormContainer', () => ({
    onSubmitButtonClick() {
      const flattenedComments: ApiComment[] = [];
      for (const floorId in state.comments) {
        state.comments[floorId].forEach((comment) => {
          flattenedComments.push({
            content: comment.content,
            floorId: floorId,
            floorPlanPositionX: comment.position[0],
            floorPlanPositionY: comment.position[1],
            spaceId: comment.spaceId,
            spaceName: comment.spaceName,
          });
        });
      }
      (this.$refs.submitFormCommentInput as HTMLInputElement).value =
        JSON.stringify(flattenedComments);
    },
  }));
}

export function initializeProposalFeedback() {
  const page = document.querySelector(
    'body.page-proposal-feedback'
  ) as HTMLBodyElement | null;
  if (page) {
    handleProposalFeedbackPage(page);
  }

  // Clear any comments in localstorage on submit
  const submittedPage = document.querySelector(
    'body.page-proposal-feedback-submitted'
  ) as HTMLBodyElement | null;
  if (submittedPage) {
    localStorage.setItem(
      FEEDBACK_COMMENTS_LOCAL_STORAGE_KEY,
      JSON.stringify({})
    );
  }
}
