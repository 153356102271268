import 'htmx.org';
import Alpine from 'alpinejs';
import { initializeProposalBriefing } from './proposalBriefing';
import { initializeProposalFeedback } from './proposalFeedback';
import { initializeProposalWelcome } from './proposalWelcome';

const initializePage = () => {
  initializeProposalBriefing();
  initializeProposalFeedback();
  initializeProposalWelcome();
  Alpine.start();
};

export { initializePage };
